<template>
  <div class="footer-bar-box">
    <div class="footer-bar">
      <div class="container">

        <div class="item">
          <div class="title ">
            Resources
            <div class="button" @click="openFooterItem('Resources')"> &nbsp;</div>
            <svg ref="ResourcesIcon" class="icon" xmlns="http://www.w3.org/2000/svg" width="12.355" height="8.394"
                 viewBox="0 0 10.355 6.394">
              <path d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z"
                    transform="translate(-6 -8.59)" fill="currentColor"></path>
            </svg>
          </div>
          <ul class="content " ref="Resources">

            <li><a href="/referral-program"> Referral </a></li>

            <li><a href="/questions"> Faq </a></li>

          </ul>
        </div>

        <div class="item">
          <div class="title ">Company
            <div class="button" @click="openFooterItem('Company')"> &nbsp;</div>
            <svg ref="CompanyIcon" class="icon" xmlns="http://www.w3.org/2000/svg" width="12.355" height="8.394"
                 viewBox="0 0 10.355 6.394">
              <path d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z"
                    transform="translate(-6 -8.59)" fill="currentColor"></path>
            </svg>
          </div>
          <ul class="content " ref="Company">
            <li><a href="/about"> About Us </a></li>

            <li><a href="/terms-a"> Terms of Service </a></li>

            <li><a href="/policy-a"> Privacy Policy </a></li>

          </ul>
        </div>

        <div class="item">
          <div class="title ">Contact Us
            <div class="button" @click="openFooterItem('ContactUs')"> &nbsp;</div>
            <svg ref="ContactUsIcon" class="icon" xmlns="http://www.w3.org/2000/svg" width="12.355" height="8.394"
                 viewBox="0 0 10.355 6.394">
              <path d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z"
                    transform="translate(-6 -8.59)" fill="currentColor"></path>
            </svg>
          </div>
          <ul class="content " ref="ContactUs">

            <li style="color: #8492A6;">
<!--              <map-pin-icon class="fea icon-sm icons"></map-pin-icon>-->
              <!--                  <i class="fa fa-map-marker  fa-lg" aria-hidden="true"></i>-->
<!--              &nbsp;Unit 5 Hadrians Way, Rugby, <br/>United Kingdom, CV21 1ST-->
<!--              22 Regal Court, Manor Road, Beverley, England, HU17 7GD, UK-->
            </li>
            <li style="margin-top: 5px;">
              <mail-icon class="fa-inbox"></mail-icon>
              <!--                  <i class="fa fa-envelope" aria-hidden="true"></i>-->
              &nbsp;<a href="mailto:info@inccrypto.com">info@inccrypto.com</a>
            </li>
<!--            <li style="margin-top: 5px;">-->
<!--              <a href="https://www.facebook.com/idminings" target="_blank"><img src="/images/social/facebook.png" style="width: 28px;" alt="facebook"/></a>-->
<!--              <a href="https://twitter.com/IDMining_" target="_blank"><img src="/images/social/twitter.png" style="width: 28px;margin-left: 8px;" alt="twitter"/></a>-->
<!--              <a href="https://www.instagram.com/id_mining/" target="_blank"><img src="/images/social/instagram.png" style="width: 28px;margin-left: 8px;" alt="instagram"/></a>-->
<!--            </li>-->
          </ul>
        </div>

<!--        <div class="item">-->

<!--          <div class="right">-->
<!--            <img src="/images/secure.png" alt="Best Cloud Mining Websites" title="Best Cloud Mining Websites">-->
<!--          </div>-->
<!--        </div>-->

      </div>
    </div>
    <div class="bottom-footer">
      <div class="container">
        <div class="item" style="margin: auto;">
<!--          <a href="https://find-and-update.company-information.service.gov.uk/search?q=12145667" target="_blank">-->
<!--          <a href="https://find-and-update.company-information.service.gov.uk/company/13573315" target="_blank">-->
<!--            <img src="/images/app/about.png" width="60px;">-->
<!--          </a>-->
          Copyright © 2020-2023 <a class="copyright__content--link" href="/">IncCrypto</a>
          ALL RIGHT RESERVED.
        </div>
        <!--        <div class="item" style="margin-right: auto;margin-left: 0">Support</div>-->
        <!--        <div class="item" style="margin-right: auto;margin-left: 0">Terms</div>-->
        <!--        <div class="item" style="margin-right: auto;margin-left: 0">Privacy</div>-->
      </div>
    </div>
  </div>
</template>

<script>
import {MailIcon} from "vue-feather-icons";


export default {
  name: "FooterBar",
  components: {MailIcon},
  data() {
    return {
      ccordion: true
    }
  },
  mounted() {
    const that = this;
    // if (window.outerWidth >= 600) {
    //   this.footerWidgetAccordion()
    // }
    // this.footerWidgetAccordion()
    window.addEventListener("resize", that.debounce(() => {
      let arr = [
        'Resources', 'Company', 'ContactUs'
      ]
      arr.forEach(item => {
        let current = this.$refs[item];
        let currentIcon = this.$refs[item + 'Icon']
        if (window.outerWidth >= 800) {
          current.classList.add("active");
          currentIcon.classList.add('active')
          that.slideDown(current)

        } else {
          current.classList.remove("active");
          currentIcon.classList.remove('active')
          that.slideUp(current)
        }
      })
    }, 500))

    // arr.forEach(item => {
    //   let current = this.$refs[item];
    //   let currentIcon = this.$refs[item + 'Icon']
    //   if (window.outerWidth >= 600) {
    //     current.classList.remove("active");
    //     currentIcon.classList.remove('active')
    //   } else {
    //
    //   }
    // })

  },
  destroyed() {
    window.removeEventListener('resize', function () {

    })
  },
  methods: {
    //简单的防抖函数
    debounce(func, wait, immediate) {
      //定时器变量
      let timeout;
      return function () {
        //每次触发scrolle，先清除定时器
        clearTimeout(timeout);
        //指定多少秒后触发事件操作handler
        timeout = setTimeout(func, wait);
      };
    },
    footerWidgetAccordion() {
      const that = this;
      that.accordion = false;
      let arr = [
        'Resources', 'Company', 'ContactUs'
      ]
      arr.forEach(item => {
        this.openFooterItem(item)
      })
    },
    openFooterItem(name) {
      console.log(name)
      let current = this.$refs[name];
      let currentIcon = this.$refs[name + 'Icon']
      console.log(current)
      if (current.classList.contains("active")) {
        current.classList.remove("active");
        currentIcon.classList.remove('active')
        this.slideUp(current)
      } else {
        current.classList.add("active");
        currentIcon.classList.add('active')
        this.slideDown(current)
      }

    },
    slideUp(target, time) {
      const duration = time ? time : 500;
      target.style.transitionProperty = "height, margin, padding";
      target.style.transitionDuration = duration + "ms";
      target.style.boxSizing = "border-box";
      target.style.height = target.offsetHeight + "px";
      target.offsetHeight;
      target.style.overflow = "hidden";
      target.style.height = 0;
      window.setTimeout(function () {
        target.style.display = "none";
        target.style.removeProperty("height");
        target.style.removeProperty("overflow");
        target.style.removeProperty("transition-duration");
        target.style.removeProperty("transition-property");
      }, duration);
    },
    slideDown(target, time) {
      const duration = time ? time : 500;
      target.style.removeProperty("display");
      let display = window.getComputedStyle(target).display;
      if (display === "none") display = "block";
      target.style.display = display;
      const height = target.offsetHeight;
      target.style.overflow = "hidden";
      target.style.height = 0;
      target.offsetHeight;
      target.style.boxSizing = "border-box";
      target.style.transitionProperty = "height, margin, padding";
      target.style.transitionDuration = duration + "ms";
      target.style.height = height + "px";
      window.setTimeout(function () {
        target.style.removeProperty("height");
        target.style.removeProperty("overflow");
        target.style.removeProperty("transition-duration");
        target.style.removeProperty("transition-property");
      }, duration);
    },
  }
}
</script>

<style scoped lang="scss">

.footer-bar {
  padding-bottom: 64px;

  .container {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .item {
    //width: 100%;
    min-width: 20%;
    //padding-right: 45px;
    //margin: 15px 0;
    //margin-bottom: 15px;

    .title {
      height: 22px;
      font-size: 16px;
      font-family: "Nunito", sans-serif;
      font-weight: 600;
      color: #273444;
      line-height: 22px;
      //text-align: center;
      position: relative;
      margin-bottom: 16px;

      .button {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: transparent;
        left: 0;
        top: 0;
        display: none;
        cursor: pointer;
      }

      .icon {
        position: absolute;
        right: 0;
        top: 38%;
        transition: 0.3s;
        display: none;

        &.active {
          transform: rotate(180deg);
        }
      }
    }

    .content {
      &.active {
        //height: 0;
        //overflow: hidden;
        display: none;
      }


      li {
        //margin-top: 10px;
        a {
          display: inline-block;
          height: 28px;
          font-size: 16px;
          font-family: "Nunito", sans-serif;
          color: #8492A6;
          line-height: 28px;

          &:hover {
            color: #222226;
          }
        }
      }
    }

    .right {
      padding-top: 0px;
      //margin-left: auto;
      //margin-right: auto;
      text-align: center;

      img {
        width: 140px;
      }
    }
  }


}


.bottom-footer {
  .container {
    border-top: 1px solid #EFF2F7;
    height: 98px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .item {
      //padding: 30px 0;
      //height: 20px;
      font-size: 15px;
      //font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8492A6;
      line-height: 20px;
      //margin-left: 40px;
    }
  }
}


@media only screen and (max-width: 800px) {
  .footer-bar {
    //text-align: center;
    .item {
      //text-align: center;
      width: 100%;
      margin: 10px 0;

      .button {
        display: block !important;
      }

      .icon {
        display: block !important;
      }

      .right {
        margin-top: 40px;

        img {
          width: 90px;
        }
      }
    }
  }
}

//
//
//@media only screen and (min-width: 600px){
//
//  .footer-bar{
//    .item{
//      width: 33.33%;
//      margin: 10px 0;
//    }
//  }
//}


@media only screen and (max-width: 700px) {
  .bottom-footer {
    .container {
      flex-direction: column;
      height: auto;
      padding-top: 10px;
      //padding-bottom: 10px;
      .item {
        width: 100%;
        margin: 10px 0;
      }
    }
  }
}
</style>
