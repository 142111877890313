<script>
import accountApi from '@/apis/accountApi';
import homeApi from '@/apis/homeApi';
import storage from '@/storage';
import translate from "google-translate-open-api"

/**
 * Navbar component
 */
export default {
  name: 'Navbar',
  data() {
    return {
      isCondensed: false,
      alert: {
        content: "",
        presignedUrl: null,
        ifAlert: false,
        type: 2
      },
      offcanvasHeaderMenuOpen: false,
      path: '',
      login: false,
      email: '',
      linkList: [
        {title: 'Home', path: '/'},
        // {title: 'Plans', path: '/plans'},
        {title: 'Dashboard', path: '/profile'},
        {title: 'Referral', path: '/referral-program'},
        // {title: 'FAQ', path: '/faq'},
        // {title: 'Blog', path: '/index-blog'},
        // {title: 'Reviews', path: '/reviews'},
        {title: 'About Us', path: '/about'},
        {title: 'Faq', path: '/questions'},

      ],
      isShowMyCenterPanel: false,
      isShowLanguagePanel: false,
    };
  },
  props: {
    isWhiteNavbar: {
      type: Boolean,
    },
    navLight: {
      type: Boolean,
    },
    isIcons: {
      type: Boolean,
    },
  },
  mounted() {
    this.observeDOM()
    const that = this
    let userId = storage.localGet("userId")
    if (userId) {
      const flag = window.matchMedia("(min-width: 992px)").matches
      this.login = true
      this.email = userId.email
      const uA = navigator.userAgent;
      const isAndroid = uA.indexOf('Android') > -1 || uA.indexOf('Adr') > -1; //android终端
      const isiOS = !!uA.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isiOS || isAndroid) {
        if (this.login && !flag) {
          // this.linkListShow = false
          // this.loginNavShow = true
          this.linkList = [
            {title: 'Home', path: '/'},
            {title: 'Dashboard', path: '/profile/overview'},
            {title: 'My Bills', path: '/profile/transactions'},
            {title: 'Deposits', path: '/profile/deposits'},
            {title: 'Withdraws', path: '/profile/withdraws'},
            // {title: 'Refund', path: '/profile/refund'},
            {title: 'My Orders', path: '/profile/myOrders'},
            {title: 'Buy Plans', path: '/plans'},
            {title: 'Referral', path: '/profile/referral'},
            {title: 'Settings', path: '/profile/setting'}
          ]
        }
      }
    }
    // window.onscroll = function () {
    //   onwindowScroll();
    // };
    console.log(this.$route)
    this.path = this.$route.path

    window.onscroll = function () {
      onwindowScroll();
    };
    //获取消息10秒一次
    // homeApi.notification(res => {
    //   this.alert = res.data
    // })
    // setInterval(() => {
    //   homeApi.notification(res => {
    //     //相同的内容不在展示
    //     if (this.alert.content == res.data.content) {
    //       res.data.ifAlert = this.alert.ifAlert
    //     }
    //     this.alert = res.data

    //   })
    // }, 10 * 1000)
    function onwindowScroll() {
      if (
          document.body.scrollTop > 50 ||
          document.documentElement.scrollTop > 50
      ) {
        document.getElementById("main__header").classList.add("sticky");
      } else {
        document.getElementById("main__header").classList.remove("sticky");
      }

      // if (
      //     document.body.scrollTop > 100 ||
      //     document.documentElement.scrollTop > 100
      // ) {
      //   document.getElementById("back-to-top").style.display = "inline";
      // } else {
      //   document.getElementById("back-to-top").style.display = "none";
      // }
    }

    var links = document.getElementsByClassName("side-nav-link-ref");

    var matchingMenuItem = null;
    for (let i in links) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("active");
            const parent4 = parent3.parentElement;
            if (parent4) {
              const parent5 = parent4.parentElement;
              parent5.classList.add("active");
            }
          }
        }
      }
    }
  },
  methods: {
    observeDOM() {
      const observer = new MutationObserver((mutationsList, observer) => {
        for (const mutation of mutationsList) {
          if (mutation.addedNodes) {
            for (const node of mutation.addedNodes) {
              if (node.tagName === "JDIV") {
                node.style.display = "none";
              }
              if (node.tagName === "jdiv") {
                node.style.display = "none";
              }
            }
          }
        }
      });
      const config = {childList: true, subtree: true};
      const targetNode = document.documentElement; // 或者指定包含 JivoSite 的父元素
      observer.observe(targetNode, config);
    },
    offcanvasHeaderMenuOpenBtn() {
      this.offcanvasHeaderMenuOpen = true;
    },
    offcanvasHeaderMenuCloseBtn() {
      this.offcanvasHeaderMenuOpen = false;

    },
    /**
     * Toggle menu
     */
    toggleMenu() {
      this.isCondensed = !this.isCondensed;
      if (this.isCondensed) {
        document.getElementById("navigation").style.display = "block";
      } else document.getElementById("navigation").style.display = "none";
    },

    /**
     * Menu clicked show the submenu
     */
    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling.nextSibling;

      if (nextEl && !nextEl.classList.contains("open")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("open");
        }
        nextEl.classList.add("open");
      } else if (nextEl) {
        nextEl.classList.remove("open");
      }
      return false;
    },
    logout() {
      accountApi.logout(() => {
        storage.localDel("userId")
        if (location.href != "/") {
          location.href = "/"
        } else {
          location.reload()
        }

      })
    },
    openLanguagePanel() {
      // this.$refs.dropdownMenuLink.style.display = 'block'
      console.log(111)
      this.isShowLanguagePanel = !this.isShowLanguagePanel;
    },
    GTranslateGetCurrentLang() {
      var keyValue = document['cookie']
          .match('(^|;) ?googtrans=([^;]*)(;|$)');
      return keyValue ? keyValue[2].split('/')[2] : null;
    },
    GTranslateFireEvent(element, event) {
      try {
        if (document.createEventObject) {
          var evt = document.createEventObject();
          element.fireEvent('on' + event, evt)
        } else {
          var evt = document.createEvent('HTMLEvents');
          evt.initEvent(event, true, true);
          element.dispatchEvent(evt)
        }
        // $(".skiptranslate").css("display", "none");
        document.getElementsByClassName('skiptranslate')[0].style.display = "none"
        $("body").removeAttr("style");
        document.body.style = ""
      } catch (e) {
      }
    },
    doGTranslate(lang_pair) {
      const vm = this;
      try {
        if (lang_pair.value)
          lang_pair = lang_pair.value;
        if (lang_pair == '')
          return;
        var lang = lang_pair.split('|')[1];
        if (vm.GTranslateGetCurrentLang() == null
            && lang == lang_pair.split('|')[0])
          return;
        var teCombo;
        var sel = document.getElementsByTagName('select');
        for (var i = 0; i < sel.length; i++)
          if (sel[i].className.indexOf('goog-te-combo') != -1) {
            teCombo = sel[i];
            break;
          }
        if (document.getElementById('google_translate_element2') == null
            || document.getElementById('google_translate_element2').innerHTML.length == 0
            || teCombo.length == 0 || teCombo.innerHTML.length == 0) {
          setTimeout(function () {
            vm.doGTranslate(lang_pair)
          }, 500)
        } else {
          teCombo.value = lang;
          vm.GTranslateFireEvent(teCombo, 'change');
          vm.GTranslateFireEvent(teCombo, 'change')
        }
        this.isShowLanguagePanel = false
      } catch (e) {

        console.log(e)
      }
    },
  },
};
</script>
<style lang="scss" scoped>


.imageAlert {
  z-index: 99999;
  border-radius: 0;

  img {
    height: 100px;
  }

  @media screen and (max-width: 768px) {
    position: fixed;
    left: 0;
    margin: 2.5%;
    width: 95%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    height: 95%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
<template>
  <!-- Navbar STart -->
  <header id="header-nav" class="new-navbar">

    <div class="main__header" id="main__header">
      <div class="container">
        <div class="logo-box">
          <router-link class="logo-icon" to="/">
            <img src="/images/logo.png" alt=""/>
          </router-link>
        </div>
<!--        <div class="language">-->
<!--          <div class="navbar-collapse" id="navbarNavDarkDropdown">-->
<!--            <div class="dropdown">-->
<!--              <a class="btn dropdown-toggle header__menu&#45;&#45;link"-->
<!--                 :class="{'dropdown-toggle': isShowLanguagePanel}" @click="openLanguagePanel"-->
<!--                 href="javascript: void (0)"-->
<!--                 style="font-weight:400;" role="button" id="dropdownMenuLink" data-toggle="dropdown"-->
<!--                 aria-expanded="false">-->
<!--                <i class="fa fa-globe"></i> Language-->
<!--              </a>-->
<!--              <div style="display: block" class="dropdown-menu" aria-labelledby="dropdownMenuLink"-->
<!--                   ref="dropdownMenuLink"-->
<!--                   v-show="isShowLanguagePanel">-->
<!--                <a class="dropdown-item header__menu&#45;&#45;link" style="font-weight:400;" href="#"-->
<!--                   @click="doGTranslate('en|en');"><i class="fi fi-gb"></i> English</a>-->
<!--                <a class="dropdown-item header__menu&#45;&#45;link" style="font-weight:400;" href="#"-->
<!--                   @click="doGTranslate('en|de');"><i class="fi fi-de"></i> Deutsch</a>-->
<!--                <a class="dropdown-item header__menu&#45;&#45;link" style="font-weight:400;" href="#"-->
<!--                   @click="doGTranslate('en|fr');"><i class="fi fi-fr"></i> Français</a>-->
<!--                <a class="dropdown-item header__menu&#45;&#45;link" style="font-weight:400;" href="#"-->
<!--                   @click="doGTranslate('en|ja');"><i class="fi fi-jp"></i> 日本語</a>-->
<!--                <a class="dropdown-item header__menu&#45;&#45;link" style="font-weight:400;" href="#"-->
<!--                   @click="doGTranslate('en|ko');"><i class="fi fi-kr"></i> 한국어</a>-->
<!--                <a class="dropdown-item header__menu&#45;&#45;link" style="font-weight:400;" href="#"-->
<!--                   @click="doGTranslate('en|pt');"><i class="fi fi-pt"></i> Português</a>-->
<!--                <a class="dropdown-item header__menu&#45;&#45;link" style="font-weight:400;" href="#"-->
<!--                   @click="doGTranslate('en|ru');"><i class="fi fi-ru"></i> Русский</a>-->
<!--                <a class="dropdown-item header__menu&#45;&#45;link" style="font-weight:400;" href="#"-->
<!--                   @click="doGTranslate('en|sp');"><i class="fi fi-es"></i> Español</a>-->
<!--                <a class="dropdown-item header__menu&#45;&#45;link" style="font-weight:400;" href="#"-->
<!--                   @click="doGTranslate('en|tr');"><i class="fi fi-tr"></i> Türk</a>-->
<!--                <a class="dropdown-item header__menu&#45;&#45;link" style="font-weight:400;" href="#"-->
<!--                   @click="doGTranslate('en|vi');"><i class="fi fi-vi"></i> Tiếng Việt</a>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="navigation">
          <ul class="navigation-menu" style="justify-content: right;" :class="{ 'nav-light': navLight === true }">
            <li class="nav__menu--items">
              <router-link to="/" id="/" class="header__menu--link" :class="{'header__menu--link_active': '/' == path}">
                HOME
              </router-link>
            </li>
            <li class="nav__menu--items">
              <router-link to="/profile/overview" class="header__menu--link"
                           :class="{'header__menu--link_active': path.indexOf('/profile/overview') != -1}">DASHBOARD
              </router-link>
            </li>
            <li class="nav__menu--items">
              <router-link to="/referral-program" class="header__menu--link" active-class="header__menu--link_active">REFERRAL
              </router-link>
            </li>

            <li class="nav__menu--items">
              <router-link to="/about" class="header__menu--link" active-class="header__menu--link_active">ABOUT US
              </router-link>
            </li>
            <li class="nav__menu--items">
              <router-link to="/questions" class="header__menu--link"
                           active-class="header__menu--link_active">FAQ
              </router-link>
            </li>

          </ul>
        </div>
        <div class="right-extend header__account">
          <ul class="d-flex align-items-center">

            <li v-if="login" class="header__account--items d-sm-u-none">
              <div class="dropdown">
                <!--                    /profile-->
                <a href="#" class="dropdown-toggle header__menu--link"
                   @click="isShowMyCenterPanel = !isShowMyCenterPanel"> {{ email }}</a>
                <div class="dropdown-menu" style="left: auto;right: 0;display: block" aria-labelledby="dropdownMenuLink"
                     ref="dropdownMenuMyCenter"
                     v-show="isShowMyCenterPanel">
                  <router-link to="/profile/setting">
                    <a class="dropdown-item" style="font-weight:400;"
                       @click="isShowMyCenterPanel = !isShowMyCenterPanel"
                    > Change Password</a>
                  </router-link>
                  <a class="dropdown-item header__menu--link" style="font-weight:400;" href="#"
                     @click="logout">Sign out</a>
                </div>
              </div>
            </li>
            <li v-else class="right-extend_items header__account--items">
              <a class="but but_login" href="/auth-login">
                SIGN IN
              </a>
              <a class="but but_signup " href="/auth-signup">
                SIGN UP
              </a>
            </li>
          </ul>
        </div>


        <div class="offcanvas__header--menu__open " style="margin-right: .625rem;padding: 10px 0">
          <div class="offcanvas__header--menu__open--btn" href="javascript:void(0)"
               @click.stop="offcanvasHeaderMenuOpenBtn" data-offcanvas="">
            <svg xmlns="http://www.w3.org/2000/svg" class="ionicon offcanvas__header--menu__open--svg"
                 viewBox="0 0 512 512">
              <path fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10"
                    stroke-width="32" d="M80 160h352M80 256h352M80 352h352"></path>
            </svg>
            <!--            <span class="visually-hidden">Offcanvas Menu Open</span>-->
          </div>
        </div>

        <!--        <div class="buy-button window" style="line-height: 68px;" v-if="isIcons !== true">-->
        <!--          <a href="/auth-login" class="btn" :class="{-->
        <!--            'btn-light': navLight === true,-->
        <!--            'btn-primary': navLight !== true,-->
        <!--          }">Sign in</a>-->
        <!--        </div>-->
      </div>
    </div>


    <div class="offcanvas__header" :class="{'open': offcanvasHeaderMenuOpen}">
      <div class="offcanvas__inner">
        <div class="offcanvas__logo">
          <router-link class="offcanvas__logo_link" to="/" v-if="navLight !== true">
            <img class="logo__light logo__light" src="/images/logo.png" height="32" alt=""/>
          </router-link>
          <router-link class="offcanvas__logo_link" to="/" v-else>
            <img class="logo__light  l-dark" src="/images/logo.png" height="32" alt=""/>
            <img class="logo__light  l-light" src="/images/logo.png" height="32" alt=""/>
          </router-link>
          <!--          <a class="offcanvas__logo_link" href="/">-->
          <!--            <img class="logo__light" src="/assets/img/logo/logo.png">-->
          <!--          </a>-->
          <button class="offcanvas__close--btn" data-offcanvas="" @click.s.stop="offcanvasHeaderMenuCloseBtn">close
          </button>
        </div>
        <nav class="offcanvas__menu">
          <ul class="offcanvas__menu_ul">
            <li class="offcanvas__menu_li" v-for="item in linkList" :key="item.path">
              <a class="offcanvas__menu_item" :class="{'header__menu--link_active': item.path == path}"
                 :href="item.path" :id="item.path">{{ item.title }}</a>
            </li>
          </ul>
          <div v-if="login" class="header__account offcanvas__account">
            <ul class=" align-items-center login-but_box">
              <li class="header__account--items">
                <a href="/profile/overview"> {{ email }}</a>
              </li>
              <br/>
              <li class="header__account--items">
                <a class=" but but_signup " @click="logout" href="#">
                  Sign out
                </a>
              </li>

            </ul>
          </div>
          <div v-else class="header__account offcanvas__account">
            <ul class="d-flex align-items-center login-but_box">
              <li class="header__account--items">
                <a class=" but but_login" href="/auth-login">
                  SIGN IN
                </a>
                <a class=" but but_signup " style="margin-left: 0.25rem" href="/auth-signup">
                  SIGN UP
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </header>
  <!--end header-->
  <!-- Navbar End -->
</template>
<style lang="scss">
@import "@/style/style.scss";

:root {
  //--logoIconPadding: 10px;
  //--logoImgWidth: 105px;
  //--headerMenuLinkHeight: 70px;
  //--headerMenuLinkFs: 11px;
  //
  //--headerMenuLinkPadding: 10px;
  //--headerMenuLinkIconSize: 11px;
  //--headerMenuLinkIconML: 5px;
  //
  //--butWidth: 78px;
  //--butHeight: 31px;
  //--butBR: 4px;
  //--butFS: 11px;
  //--butML: 15px;


  --logoIconPadding: 10px;
  --logoImgWidth: 130px;
  --headerMenuLinkHeight: 87px;
  --headerMenuLinkFs: 14px;

  --headerMenuLinkPadding: 13px;
  --headerMenuLinkIconSize: 14px;
  --headerMenuLinkIconML: 7px;

  --butWidth: 96px;
  --butHeight: 39px;
  --butBR: 5px;
  --butFS: 14px;
  --butML: 19px;

  //--logoIconPadding: 10px;
  //--logoImgWidth: 150px;
  //--headerMenuLinkHeight: 100px;
  //--headerMenuLinkFs: 16px;
  //
  //--headerMenuLinkPadding: 17px;
  //--headerMenuLinkIconSize: 16px;
  //--headerMenuLinkIconML: 9px;
  //
  //--butWidth: 112px;
  //--butHeight: 45px;
  //--butBR: 6px;
  //--butFS: 16px;
  //--butML: 22px;
}

.header__menu--link_active {
  color: #222226 !important;
}

//
@media only screen and (min-width: 1200px) {
  :root {
    //--containerWidth: 960px;
    //--logoIconPadding: 10px;
    //--logoImgWidth: 130px;
    //--headerMenuLinkHeight: 87px;
    //--headerMenuLinkFs: 14px;
    //
    //--headerMenuLinkPadding: 13px;
    //--headerMenuLinkIconSize: 14px;
    //--headerMenuLinkIconML: 7px;
    //
    //--butWidth: 96px;
    //--butHeight: 39px;
    //--butBR: 5px;
    //--butFS: 14px;
    //--butML: 19px;
    --logoIconPadding: 10px;
    --logoImgWidth: 150px;
    --headerMenuLinkHeight: 100px;
    --headerMenuLinkFs: 14px;

    --headerMenuLinkPadding: 16px;
    --headerMenuLinkIconSize: 16px;
    --headerMenuLinkIconML: 9px;

    --butWidth: 100px;
    --butHeight: 45px;
    --butBR: 6px;
    --butFS: 16px;
    --butML: 22px;
  }
}

@media only screen and (max-width: 1130px) {
  :root {
    --headerMenuLinkFs: 12px;

    --butWidth: 65px;
    --butHeight: 32px;
    --butBR: 6px;
    --butFS: 13px;
    --butML: 16px;
  }
}


//@media only screen and (max-width: 1065px) {
//  :root {
//    //--containerWidth: 1200px;
//    --logoIconPadding: 10px;
//    --logoImgWidth: 130px;
//    --headerMenuLinkHeight: 87px;
//    --headerMenuLinkFs: 14px;
//
//    --headerMenuLinkPadding: 13px;
//    --headerMenuLinkIconSize: 14px;
//    --headerMenuLinkIconML: 7px;
//
//    --butWidth: 80px;
//    --butHeight: 39px;
//    --butBR: 5px;
//    --butFS: 14px;
//    --butML: 19px;
//  }
//
//}




//@media only screen and (min-width: 1700px) {
//  //.header__menu--items {
//  //  margin-right: 1.75rem
//  //}
//
//  :root {
//    //--containerWidth: 1480px;
//    --logoIconPadding: 10px;
//    --logoImgWidth: 200px;
//    --headerMenuLinkHeight: 135px;
//    --headerMenuLinkFs: 21px;
//
//    --headerMenuLinkPadding: 20px;
//    --headerMenuLinkIconSize: 21px;
//    --headerMenuLinkIconML: 9px;
//
//    --butWidth: 149px;
//    --butHeight: 60px;
//    --butBR: 8px;
//    --butFS: 21px;
//    --butML: 29px;
//
//
//  }
//
//}

</style>
<style scoped lang="scss">
ul, li {
  padding: 0;
  margin: 0;
  list-style: none;
}


#header-nav {
  width: 100%;
  background: #FFFFFF;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;

  .main__header {

    border-bottom: 1px solid rgba(224, 224, 224, 1);

    &.sticky {
      //position: fixed;
      //width: 100%;
      //top: 0;
      background: #f9f9f9;
      //left: 0;
      z-index: 99;
      box-shadow: 0 0 7px rgb(0 0 0 / 15%);
      transition: 0.3s;
    }

    .container {
      max-width: var(--containerWidth);
      //width: var(--containerWidth);
      display: flex;
      align-items: center;
    }

    .navigation {
      flex: 1;
      width: 0;
    }

    .language {
      margin-left: 15px;
    }

    .logo-box {
      line-height: 0;

      .logo-icon {
        display: inline-block;
        padding: var(--logoIconPadding) 0;
      }

      img {
        width: var(--logoImgWidth);
      }
    }

    .navigation-menu {
      display: flex;
      align-items: center;

      .nav__menu--items {
        .header__menu--link {
          height: var(--headerMenuLinkHeight);
          font-size: var(--headerMenuLinkFs);
          font-family: Nunito,sans-serif;
          color: #8492A6;
          line-height: var(--headerMenuLinkHeight);
          padding: 0 var(--headerMenuLinkPadding);
          text-align: center;

          &:hover, .header__menu--link_active {
            color: #0a0a1a;
          }

          .icon {
            width: var(--headerMenuLinkIconSize);
            height: var(--headerMenuLinkIconSize);
            margin-left: var(--headerMenuLinkIconML);
          }
        }
      }
    }

    .right-extend {
      .right-extend_items {
        //background: red;
        .but {
          width: var(--butWidth);
          height: var(--butHeight);
          background: RGBA(12, 102, 255, 1);
          border-radius: var(--butBR);
          font-size: var(--butFS);
          font-family: Nunito,sans-serif;
          color: #FFFFFF;
          line-height: var(--butHeight);
          text-align: center;
          display: inline-block;
          margin-left: var(--butML);

          &:focus, &:hover {
            background: RGBA(12, 102, 255, .8);
          }

          &.but_signup {
            //background: RGBA(255, 190, 61, 1);
            background: #ff9259;

            &:focus, &:hover {
              background: RGBA(255, 190, 61, .8);
            }
          }

        }
      }
    }
  }

  .offcanvas__header--menu__open {
    line-height: 1;
    display: none;
    margin-left: auto;
  }

  @media only screen and (max-width: 1000px) {
    .offcanvas__header--menu__open {
      display: block
    }
  }

  .offcanvas__header--menu__open--svg {
    width: 32px
  }

  .offcanvas__header--menu__open--btn > * {
    pointer-events: none
  }

  .offcanvas__header {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 300px;
    height: 100vh;
    transition: all 0.3s ease 0s;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    opacity: 0;
    visibility: hidden;
    padding-bottom: 120px
  }

  @media only screen and (min-width: 480px) {
    .offcanvas__header {
      max-width: 320px
    }
  }

  .offcanvas__header.open {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
    visibility: visible
  }

  .offcanvas__header.open ~ .offcanvas-overlay {
    visibility: visible;
    opacity: .75
  }

  .offcanvas-overlay {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    opacity: 0;
    background-color: #000000;
  }

  .offcanvas__inner {
    position: relative;
    height: 100%
  }

  .offcanvas__logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 20px 15px
  }

  .offcanvas__logo img {
    max-width: 135px
  }

  .offcanvas__close--btn {
    position: relative;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    width: 1.25rem;
    height: 1.25rem;
    padding: 0;
    text-indent: -9999px;
    border: none;
    background-color: transparent
  }

  .offcanvas__close--btn::before, .offcanvas__close--btn::after {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    margin-top: -1px;
    content: "";
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: #000
  }

  .offcanvas__close--btn::after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
  }

  .offcanvas__menu {
    overflow-y: auto;
    height: 100%
  }

  .offcanvas__menu_ul {
    overflow: auto;
    margin: 0;
    padding: 0;
    list-style: none
  }

  .offcanvas__menu_li {
    position: relative;
    border-bottom: 1px solid #e4e4e4;
  }

  .offcanvas__menu_li:first-child {
    border-top: 1px solid #e4e4e4;
  }

  .offcanvas__menu_item {
    line-height: 1;
    display: block;
    padding: 15px 20px;
    text-transform: uppercase;
    color: #8492A6;
  }

  //
  //.offcanvas__menu_item_active {
  //  color: #few343;
  //}

  .offcanvas__sub_menu {
    display: none;
    margin: 0;
    padding: 0;
    list-style: none
  }

  .offcanvas__sub_menu_li {
    position: relative;
    border-top: 1px solid #e4e4e4;
  }

  .offcanvas__sub_menu_item {
    line-height: 1;
    display: block;
    padding: 15px 0 15px 30px
  }

  .offcanvas__sub_menu_item ~ .offcanvas__sub_menu .offcanvas__sub_menu_item {
    padding-left: 40px
  }

  .offcanvas__sub_menu_toggle {
    font-size: 20px;
    position: absolute;
    z-index: 9;
    top: 0;
    right: 0;
    width: 2.5rem;
    height: 2.875rem;
    padding: 0;
    border: none;
    border-radius: 0;
    background-color: transparent
  }

  .offcanvas__sub_menu_toggle::before, .offcanvas__sub_menu_toggle::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 2px;
    content: "";
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background-color: #000
  }

  .offcanvas__sub_menu_toggle:not(.active)::after {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(90deg);
    transform: translateX(-50%) translateY(-50%) rotate(90deg)
  }

  .offcanvas__account {
    margin-top: 1.125rem;
    padding: 0 1.25rem
  }


}

.login-but_box {
  //background-color: red;
  .but {
    width: var(--butWidth);
    height: var(--butHeight);
    background: RGBA(12, 102, 255, 1);
    border-radius: var(--butBR);
    font-size: var(--butFS);
    font-family: Nunito,sans-serif;
    color: #FFFFFF;
    line-height: var(--butHeight);
    text-align: center;
    display: inline-block;
    //margin-left: var(--butML);
    &:focus, &:hover {
      background: RGBA(12, 102, 255, .8);
    }

    &.but_signup {
      background: RGBA(255, 190, 61, 1);
      margin-left: 10px;

      &:focus, &:hover {
        background: RGBA(255, 190, 61, .8);
      }
    }

  }
}

.navbar-collapse {
  .but {
    border-color: transparent !important;
    color: rgba(132, 146, 166, 1) !important;

    &:hover {
      border-color: rgba(132, 146, 166, 1) !important;
      background-color: transparent !important;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .main__header {
    .navigation, .right-extend {
      display: none;
    }


  }
  .offcanvas__header {
    display: block;
  }
}
/* iPhone 5/SE */
@media only screen and (max-width: 320px) {
  .main__header {
    --logoImgWidth: 100px;
  }
  //.page_section_two {
  //  .container{
  //    --containerWidth: 150px;
  //  }
  //}
}
</style>

